import React from "react"
// import SEO from "../components/seo"
import {  Link  } from "gatsby"

const NotFoundPage = () => (
  <>
   <div className="uk-text-center" id="main">
        <div class="fof">
            <h1>Página no encontrada</h1>
            <br />
        <Link to="/"><a className="btn btn--primary btn--suscribe">Volver a inicio</a></Link>
      </div>
      </div>
  </>
)

export default NotFoundPage
